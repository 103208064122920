// import React from 'react'
import './style.css'


import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Sidebar() {
  const [isUsersDropdownOpen, setIsUsersDropdownOpen] = useState(false);
  const [isCurriculumDropdownOpen, setIsCurriculumDropdownOpen] = useState(false);
  const [isLearningDropdownOpen, setIsLearningDropdownOpen] = useState(false);

  const toggleDropdown = (dropdown) => {
    switch (dropdown) {
      case 'users':
        setIsUsersDropdownOpen(!isUsersDropdownOpen);
        break;
      case 'curriculum':
        setIsCurriculumDropdownOpen(!isCurriculumDropdownOpen);
        break;
      case 'learning':
        setIsLearningDropdownOpen(!isLearningDropdownOpen);
        break;
      default:
        break;
    }
  };

  return (
    <ul className="navbar-nav bg-custom-blue sidebar sidebar-dark accordion" id="accordionSidebar">
      
      <li className="sidebar-brand align-items-center justify-content-center">
        <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-cogs"></i>
        </div>
        <div className="sidebar-brand-text mx-3">SLS</div>
      </li>
      <li><hr className="sidebar-divider" /></li>            
      <li className="nav-item ">
          <a className="nav-link" href="./"><i className="fas fa-fw fa-home"></i><span>HOME</span></a>
      </li>
      <li className="nav-item ">
          <a className="nav-link" target="_blank"  href="https://sixukts.scrglab.id/"><i className="fas fa-server"></i><span>SIX</span></a>
      </li>
      <li className="nav-item ">
          <a className="nav-link" target="_blank" href="https://smartcampus.my.id/signin"><i className="fas fa-clipboard"></i><span>LMS</span></a>
      </li>
      <li><hr className="sidebar-divider" /></li>

      {/* USERS */}
      <li>
        <div className="sidebar-heading" onClick={() => toggleDropdown('users')}>
          <i className="fas fa-fw fa-users"></i>
          <span> USERS MANAGEMENT</span>
        </div>
      </li>
      
        <li className="nav-item">
          <a className="nav-link" href="/student">
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-user"></i><span>Students</span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/lecturer">
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-user-tag"></i><span>Lecturers</span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/manager">
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-user-edit"></i><span>Managers</span>
          </a>
        </li>
      <li>
        <hr className="sidebar-divider" />
      </li>

      {/* course mgt */}
      <li><div className="sidebar-heading"><i className="fas fa-fw fa-book"></i><span> course management</span></div></li>
      <li className="nav-item">
        <a className="nav-link" href="/course">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-chalkboard-teacher"></i><span>Courses</span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/offeredcourse">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-book-open"></i><span>Offered Courses</span>
        </a>
      </li>

      {/* <li className="nav-item">
        <a className="nav-link" href="/learningperiod">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-calendar-alt"></i><span>Learning Period</span>
        </a>
      </li> */}
      <li><hr className="sidebar-divider" /></li>

      {/* learning activities */}
      <li><div className="sidebar-heading"><i className="fas fa-fw fa-person-booth"></i><span> learning activities</span></div></li>
      <li className="nav-item">
        <a className="nav-link" href="/enroll">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-user-check"></i><span>Enrolled Students</span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="./">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-chart-bar"></i><span>Course Attendance</span>
        </a>
      </li>

      <li><hr className="sidebar-divider" /></li>
      {/* learning activities */}
      <li><div className="sidebar-heading"><i className="fas fa-star"></i><span> Grading</span></div></li>
      <li className="nav-item">
        <a className="nav-link" href="./">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-check-circle"></i><span>Grade</span>
        </a>
      </li>

      <li><hr className="sidebar-divider" /></li>
      {/* master data */}
      <li><div className="sidebar-heading"><i className="fas fa-database"></i><span> Master data</span></div></li>
      
      {/* <li className="nav-item">
        <a className="nav-link" href="./">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-file-alt"></i><span>Course</span>
        </a>
      </li> */}

      <li className="nav-item">
        <a className="nav-link" href="/gradecomponents">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-check-circle"></i><span>Grade Components</span>
        </a>
      </li>

      <li className="nav-item">
        <a className="nav-link" href="/master-category">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-book-open"></i><span>Course Category</span>
        </a>
      </li>
   
   
     
    </ul>
  )
}

export default Sidebar