/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Breadcrumb} from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CourseService from './CourseService';
import StudentService from '../student/StudentService';

import * as AiIcons from 'react-icons/ai';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ViewCourse = () => {
  const [name_id, setName_id] = useState('');
  const [course_format, setCourseFormat] = useState('');
  const [name_en, setName_en] = useState('');
  const [credit, setCredit] = useState('');
  const [semester, setSemester] = useState('');
  const [curriculum_year, setCurriculum_year] = useState('');
  const [description, setDescription] = useState('');
  const [courseStartTime, setCourseStartTime] = useState('');
  const [courseEndTime, setCourseEndTime] = useState('');
  const [showConfirm, setShowConfirm] = useState(false); // Popup state
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const {id} = useParams();
  const [categID, setcategID] = useState('');
  const [categName, setcategName] = useState('');
  const navigate = useNavigate();

  useEffect( () => {
    getCourseById();
  },[]);

  useEffect(() => {
    // Fetch category name after categID is set
    if (categID) {
      getCategoryByID();
    }
  }, [categID]); // This effect depends on categID

  const getCourseById = async () => {
    let res = await CourseService.getCourseById(id);
    res = res.data

    setName_id(res.data.name);
    setCourseFormat(res.data.format);
    setName_en(res.data.name_en);
    setCredit(res.data.course_credit);
    setSemester(res.data.course_semester);
    setCurriculum_year(res.data.curriculum_year);
    setDescription(res.data.summary);
    setcategID(res.data.category_id);

    const courseStartDate = res.data.course_start_date;// Convert timestamp ke millisecond dan buat Date object
    const date = new Date(courseStartDate * 1000);
    
    const courseEndDate = res.data.course_end_date;
    const dateEnd = new Date(courseEndDate * 1000);
    
    // Menggunakan metode toLocaleString untuk mengonversi ke zona waktu Indonesia (WIB)
    const options = { timeZone: 'Asia/Jakarta', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',  };
    const indonesiaTime = date.toLocaleString('id-ID', options);
    setCourseStartTime(indonesiaTime)
    const indonesiaTimeEnd = dateEnd.toLocaleString('id-ID', options);
    setCourseEndTime(indonesiaTimeEnd)
  }

  const getCategoryByID = async () => {
    try {
      let res = await StudentService.getCategoryById(categID);
      res = res.data;
      setcategName(res.data.name)
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  }
  
  
  const deleteCourse = async (id) => {
    await CourseService.deleteCourse(id);
    setShowConfirm(false); // Close popup
    navigate('/course');
  }

  const handleConfirmDelete = () => {
    if (selectedCourseId) {
      deleteCourse(selectedCourseId);
    }
    setShowConfirm(false);
  };

  const handleDeleteClick = (id) => {
    setSelectedCourseId(id);
    setShowConfirm(true); // Show popup when delete button is clicked
  };

  const handleCancelDelete = () => {
    setShowConfirm(false); // Close popup without deleting
    setSelectedCourseId(null);
  };

  // Inline styles for popup modal
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    textAlign: 'center',
    width: '300px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };
  
  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/course'>Courses Data</Breadcrumb.Item>
          <Breadcrumb.Item active>Course Detail</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Container>
      <Row>
        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course ID :</b></Col>
            <Col xs={8}>{id}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Name :</b></Col>
            <Col xs={8}>{name_id}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Description :</b></Col>
            <Col xs={8}>{description}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Credit :</b></Col>
            <Col xs={8}>{credit}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Semester :</b></Col>
            <Col xs={8}>{semester}</Col>
          </Row>
        
        </Col>

        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Format :</b></Col>
            <Col xs={8}>{course_format}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Category :</b></Col>
            <Col xs={8}>{categName}</Col>
          </Row>

          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Curriculum :</b></Col>
            <Col xs={8}>{curriculum_year}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Start Time :</b></Col>
            <Col xs={8}>{courseStartTime}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>End Time :</b></Col>
            <Col xs={8}>{courseEndTime}</Col>
          </Row>
        </Col>

      </Row>

        <Row><Col>&nbsp;</Col></Row>
        <Row className="d-flex justify-content-center">
          <Col xs="auto">
            <Link to={`/course`} className='btn btn-outline-dark btn-sm'><AiIcons.AiOutlineRollback size={18} />Back</Link>
            &nbsp;&nbsp;

            {/* TODO: */}
            {/* <Link to={`/course/edit/`+id} className='btn btn-outline-primary btn-sm'><AiIcons.AiFillEdit size={18} />Edit</Link> */}
            &nbsp;&nbsp;

            <Link title='Delete' className='btn btn-outline-danger btn-sm' onClick={() => handleDeleteClick(id)}>
              <AiIcons.AiTwotoneDelete size={18} /> Delete
            </Link>
          </Col>
        </Row>        
      </Container>
      {/* Modal konfirmasi */}
      {showConfirm && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <p>Are you sure you want to delete this course?</p>
            <button className="btn btn-danger" onClick={handleConfirmDelete}>Confirm</button>&nbsp;&nbsp;
            <button className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ViewCourse