import HttpCommon from "../../HttpCommon";

class EnrollmentService {
    getAll() {
        return HttpCommon.get("/enrolledstudent");
    }

    getEnrolledstudentById (enrolledstudentId) {
        return HttpCommon.get("/enrolledstudent/"+enrolledstudentId);
    }

    getStudentParticipant (offeredcourseid) {
        return HttpCommon.get("/enrolledstudent/offeredcourse/"+offeredcourseid);
    }

    addEnrolledstudent(enrolledstudent) {
        return HttpCommon.post("/enrolledstudent",enrolledstudent);
    }

    putEnrolledstudent(enrolledstudent, enrolledstudentId) {
        return HttpCommon.put("/enrolledstudent/"+enrolledstudentId, enrolledstudent);
    }

    deleteEnrolledstudent(enrolledstudentId) {
        return HttpCommon.delete("/enrolledstudent/"+enrolledstudentId);
    }

    getStudentToEnroll (offeredcourseid) {
        return HttpCommon.get("/enrolledstudent/studenttoenroll/"+offeredcourseid)
    }
}

export default new EnrollmentService();