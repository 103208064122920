import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';
import StudentService from './StudentService';

const AddGradeComponent = () => {
  const [name, setName] = useState('');
  const [status, setStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const initialComponent = {
    uts: 0.2,
    uas: 0.2,
    proyek: 0.15,
    studi_kasus: 0.15,
    quiz: 0.15,
    partisipasi: 0.15
  };
  const [component, setComponent] = useState(initialComponent);
  const navigate = useNavigate();
  

  const handleInputChange = (e, field) => {
    let value = parseFloat(e.target.value) || 0
    if (value > 1) {
      setErrorMessage(`Value for ${field} cannot exceed 1.0`);
      // value = 10; // Set nilai ke 1 jika pengguna memasukkan nilai lebih besar dari 1
    } else {
      setErrorMessage(''); // Reset error jika validasi lolos
    }
    
    setComponent({
      ...component,
      [field]: value 
    });
  };

  const saveGradeComponent = (e) => {
    e.preventDefault();
    const total = Object.values(component).reduce((sum, value) => sum + value, 0);

    if (total !== 1.0) {
      setErrorMessage(`Total value of components must equal 1.0. Current total is ${total.toFixed(2)}.`);
      return; // Jika total tidak sama dengan 1.0, hentikan proses submit
    }
    setErrorMessage('');

    StudentService.addGradeComponent({
      name: name,
      is_active: status,
      component: component,
      }).then((resp) => {
        if (resp?.response?.status == 400){
          console.log(resp.response)
          alert(resp.response.data.message)
        }

        if (resp?.response?.status == 500){
          console.log(resp.response)
          alert(resp.response.data.message)
        }

        if (!resp.data.error) {
          alert("Successfully add data grade component!")
          navigate("/gradecomponents");
        } 
      });  
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/gradecomponents'>Grade Component Data</Breadcrumb.Item>
          <Breadcrumb.Item active>Add New Grade Component</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <form onSubmit={ saveGradeComponent }>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Name</label>
          <div className='col-sm-3'><input 
            type="text"
            className="form-control"
            value={ name } 
            onChange={ (e) => setName(e.target.value) }
            placeholder="Grade Component Name"
            required/>
          </div>
        </div>

        {Object.keys(component).map((key) => (
        <div className="mb-3 row" key={key}>
          <label className="col-sm-2 col-form-label">{key.toUpperCase()}</label>
          <div className="col-sm-3">
            <input
              type="number"
              step="0.01"
              className="form-control"
              value={component[key]}
              onChange={(e) => handleInputChange(e, key)}
              placeholder={`Enter value for ${key}`}
            />
          </div>
        </div>
        ))}

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Status</label>
          <div className='col-sm-3'>
            <div className="form-check form-switch">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id="statusSwitch" 
                checked={status} 
                onChange={() => setStatus(!status)}
              />
              <label className="form-check-label" htmlFor="statusSwitch">
                {status ? 'Active' : 'Inactive'}
              </label>
            </div>
          </div>
        </div>

        {/* Tampilkan pesan error jika ada */}
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

        <div className="field">
          <Link to={`/gradecomponents`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;

          <button type="submit" className='btn btn-outline-success btn-sm'>
            <AiIcons.AiFillSave size={18} /> Save
          </button>

        </div>
        
      </form>
    </div>
  )
}

export default AddGradeComponent