/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import OfferedcourseService from './OfferedcourseService';
import CourseService from '../course/CourseService';
import LearningperiodService from '../learningperiod/LearningperiodService';
import CategoryService from '../category/CategoryService';
import LecturerService from '../lecturer/LecturerService';
import SyncMoodleService from '../SyncMoodleService';

import * as AiIcons from 'react-icons/ai';
import MoodleService from '../MoodleService';

const OfferedcourseList = () => {
  const [students, setData] = useState([]);

  useEffect( () => {    
    getCourseoffered()
  },[]);

  const getCourseoffered = async () => {
    const students = await OfferedcourseService.getEnrolledCourse();
    setData(students.data.data || []);
  }

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Jumlah item per halaman

  // Hitung total halaman
  const totalPages = Math.ceil(students.length / itemsPerPage);

  // Ambil data yang sesuai dengan halaman saat ini
  const currentOffCourse = students.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Fungsi untuk pindah halaman
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (students.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Student Data</h1>
          <a href="/student/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-user"></i> <span>Add New Student</span>
          </a>
        </div>
        <div><p className='fs-6 fw-semibold'>The student data is empty. Please add some new students !!</p></div>
      </div>
    )    
  } else {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Enrolled Course Data</h1>
          <a href="/offeredcourse/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-user"></i> <span>Add New Enrolled Course</span>
          </a>
        </div>

        <table className='table table-sm'>
          <thead>
            <tr>
            <th>ID</th>
            <th>Course</th>
            <th>Lecturer</th>
            <th>Status</th>
            {/* <th>Actions</th> */}
            </tr>
          </thead>

          <tbody className='table-group-divider'>
            { currentOffCourse.filter(course => course.format !== 'site').map((course, index) => (
                <tr key={course.id}>
                    <td>{course.id}</td>
                    <td>{course.course_id}-{course.course_name}</td>
                    <td>{course.user_id}-{course.username}</td>
                    <td>
                    {course.is_active ? (
                      <AiIcons.AiOutlineCheck size={20} color="green" />
                    ) : (
                      <AiIcons.AiOutlineClose size={20} color="red" />
                    )}
                  </td>
                    <td>
                        {/* <Link title='Detail' to={`/course/`+course.id}><BiIcons.BiDetail size={18} /></Link>&nbsp;&nbsp;&nbsp;
                        <Link title='Edit' to={`/course/edit/`+course.id}><AiIcons.AiFillEdit size={18} /></Link>&nbsp;&nbsp;&nbsp;
                        <Link title='Delete' onClick={()=>deleteCourse(course.id)}><AiIcons.AiTwotoneDelete size={18} /></Link> */}
                    </td>
                </tr>
            )) }
          </tbody>
        </table>
          {/* Pagination Controls */}
        <nav>
          <ul className="pagination">
            {[...Array(totalPages).keys()].map((number) => (
              <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(number + 1)}>
                  {number + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>      
    )
  }
}

export default OfferedcourseList