/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Breadcrumb} from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import StudentService from "./StudentService";

import * as AiIcons from 'react-icons/ai';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Badge } from 'react-bootstrap';

const ViewGradeComponent = () => {
  const {id} = useParams();
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [components, setComponents] = useState({});
  const [showConfirm, setShowConfirm] = useState(false); // Popup state
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  useEffect( () => {
    getStudentById();
  },[]);

  const deleteComponent = async (id) => {
    await StudentService.deleteGradeComponent(id);
    navigate('/gradecomponents');
  }

  const handleDeleteClick = (id) => {
    setSelectedCourseId(id);
    setShowConfirm(true);
  };

  const handleConfirmDelete = () => {
    if (selectedCourseId) {
      deleteComponent(selectedCourseId);
    }
    setShowConfirm(false);
  };

  const handleCancelDelete = () => {
    setShowConfirm(false);
    setSelectedCourseId(null);
  };

  const getStudentById = async () => {
    let res = await StudentService.getGradeComponentById(id);
    res = res.data

    setName(res.data.name);
    setStatus(res.data.is_active);
    setComponents(res.data.component);
  }  

  const navigate = useNavigate();

  // Inline styles for popup modal
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    textAlign: 'center',
    width: '300px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/gradecomponents'>Grade Component Data</Breadcrumb.Item>
          <Breadcrumb.Item active>Grade Component Detail</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Container>
        <Row>
          <Col xs={3} className='align-text-top' align='right'><b>Name :</b></Col>
          <Col xs={9}>{name}</Col>
        </Row>

        <Row>
          <Col xs={3} className='align-text-top' align='right'><b>Status :</b></Col>
            <Col xs={9}>
              {status ? (
                <Badge bg="success">Active</Badge>  // Badge Hijau untuk Active
              ) : (
                <Badge bg="danger">Inactive</Badge> // Badge Merah untuk Inactive
              )}
          </Col>
        </Row>
       
        <Row><Col>&nbsp;</Col></Row>
        {/* Tampilkan data komponen */}
        <Row>
          <Col xs={3} className='align-text-top' align='right'><b>UTS :</b></Col>
          <Col xs={9}>{components.uts}</Col>
        </Row>

        <Row>
          <Col xs={3} className='align-text-top' align='right'><b>UAS :</b></Col>
          <Col xs={9}>{components.uas}</Col>
        </Row>

        <Row>
          <Col xs={3} className='align-text-top' align='right'><b>Proyek :</b></Col>
          <Col xs={9}>{components.proyek}</Col>
        </Row>

        <Row>
          <Col xs={3} className='align-text-top' align='right'><b>Studi Kasus :</b></Col>
          <Col xs={9}>{components.studi_kasus}</Col>
        </Row>

        <Row>
          <Col xs={3} className='align-text-top' align='right'><b>Quiz :</b></Col>
          <Col xs={9}>{components.quiz}</Col>
        </Row>

        <Row>
          <Col xs={3} className='align-text-top' align='right'><b>Partisipasi :</b></Col>
          <Col xs={9}>{components.partisipasi}</Col>
        </Row>
      <br></br>
        <Row >
          <Col>
            <Link to={`/gradecomponents`} className='btn btn-outline-dark btn-sm'><AiIcons.AiOutlineRollback size={18} />Back</Link>
            &nbsp;&nbsp;
            <Link to={`/gradecomponents/edit/`+id} className='btn btn-outline-primary btn-sm'><AiIcons.AiFillEdit size={18} />Edit</Link>
            &nbsp;&nbsp;
            <Link title='Delete' className='btn btn-outline-danger btn-sm' onClick={() => handleDeleteClick(id)}>
                      <AiIcons.AiTwotoneDelete size={18} /></Link>           
          </Col>
        </Row>  

      </Container>
      {/* Modal konfirmasi */}
      {showConfirm && (
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <p>Are you sure you want to delete this course?</p>
              <button className="btn btn-danger" onClick={handleConfirmDelete}>Confirm</button>&nbsp;&nbsp;
              <button className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
            </div>
          </div>
        )}
    </div>
  )
}

export default ViewGradeComponent