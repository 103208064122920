/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import StudentService from './StudentService';

import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';

const StudentList = () => {
  const [students, setStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortOrderAff, setSortOrderAffiliation] = useState('asc');
  const itemsPerPage = 10; // Jumlah item per halaman

  useEffect( () => {
    getStudents();
  },[]);

  const getStudents = async () => {
    const students = await StudentService.getAll();
    setStudents(students.data.data || []);
  }

  // TODO:
  const deleteStudent = async (id) => {
    await StudentService.deleteStudent(id);
    getStudents();
  }

  const sortByNim = () => {
    const sortedStudents = [...students].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.username.localeCompare(b.username);
      } 
      return b.username.localeCompare(a.username);
    });
    setStudents(sortedStudents);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle urutan sortir
  };

  const sortByAffiliation = () => {
    const sortedStudentsAff = [...students].sort((a, b) => {
      if (sortOrderAff === 'asc') {
        return a.affiliation.localeCompare(b.affiliation);
      } 
      return b.affiliation.localeCompare(a.affiliation);
    });
    setStudents(sortedStudentsAff);
    setSortOrderAffiliation(sortOrderAff === 'asc' ? 'desc' : 'asc'); // Toggle urutan sortir
  };

  // Filter data siswa berdasarkan pencarian
  const filteredStudents = students.filter((student) => {
    return (
      student.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.affiliation.toLowerCase().includes(searchTerm.toLowerCase()) || 
      student.study_program.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Hitung total halaman
  const totalPages = Math.ceil(filteredStudents.length / itemsPerPage);

  // Ambil data yang sesuai dengan halaman saat ini
  const currentStudents = filteredStudents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Fungsi untuk pindah halaman
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (students.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Student Data</h1>
          <a href="/student/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-user"></i> <span>Add New Student</span>
          </a>
        </div>
        <div><p className='fs-6 fw-semibold'>The student data is empty. Please add some new students !!</p></div>
      </div>
    )    
  } 

  return(
    <div className='container-fluid'>
      {/* Subsection */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Student Data</h1>
        <a href="/student/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
          <i className="fas fa-fw fa-user"></i> <span>Add New Student</span>
        </a>
      </div>

      {/* Input Pencarian */}
      <div className='mb-3'>
          <input
            type='text'
            className='form-control'
            placeholder='Search students...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
      </div>

      <table className='table table-sm'>
        <thead>
          <tr>
          <th onClick={sortByNim} style={{ cursor: 'pointer' }}>
            Student ID (NIM) {sortOrder === 'asc' ? <AiIcons.AiOutlineArrowUp /> : <AiIcons.AiOutlineArrowDown />}
          </th>
          <th>
            Full Name
          </th>
          <th onClick={sortByAffiliation} style={{ cursor: 'pointer' }}>
            Affiliation {sortOrderAff === 'asc' ? <AiIcons.AiOutlineArrowUp /> : <AiIcons.AiOutlineArrowDown />}
          </th>
          <th>Department</th>            
          <th>Email</th>
          <th>Actions</th>
          </tr>
        </thead>
        <tbody className='table-group-divider'>
          { currentStudents.map((student, index) => (
              <tr key={student.mdl_id}>
                  <td>{student.username}</td>
                  <td>{`${student.firstname} ${student.middlename} ${student.lastname}`}</td>
                  <td>{student.affiliation}</td>
                  <td>{`${student.degree}-${student.study_program}`}</td>
                  <td>{student.email}</td>
                  {/* TODO: */}
                  <td>
                      <Link title='Detail' to={`/student/`+student.mdl_id}>
                        <BiIcons.BiDetail size={18} />
                      </Link>
                      &nbsp;&nbsp;&nbsp;
                      <Link title='Edit' to={`/student/edit/`+student.mdl_id}>
                        <AiIcons.AiFillEdit size={18} />
                      </Link>&nbsp;&nbsp;&nbsp;

                      {/* <Link title='Delete' onClick={()=>deleteStudent(student.id)}><AiIcons.AiTwotoneDelete size={18} /></Link> */}
                  </td>
              </tr>
          )) }
        </tbody>
      </table>
      {/* Pagination Controls */}
      <nav>
        <ul className="pagination">
          {[...Array(totalPages).keys()].map((number) => (
            <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(number + 1)}>
                {number + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>      
  )
}

export default StudentList