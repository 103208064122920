/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import OfferedcourseService from '../offeredcourse/OfferedcourseService';
import CourseService from '../course/CourseService';
import LearningperiodService from '../learningperiod/LearningperiodService';
import CategoryService from '../category/CategoryService';
import EnrollmentService from './EnrollmentService';
import SyncMoodleService from '../SyncMoodleService';
import StudentService from '../student/StudentService';
import { useNavigate } from 'react-router-dom';

import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import * as BsIcons from 'react-icons/bs';
import MoodleService from '../MoodleService';

const EnrollmentList = () => {
  const [students, setStudents] = useState([]);
  const [studentid, setStudentid] = useState('');

  //untuk modal
  const [offeredcourseid, setOfferedcourseid] = useState('');
  const [coursename, setCoursename] = useState('');
  
  const [lgShow, setLgShow] = useState(false);

  const handleClose = () => setLgShow(false);
  const handleShow = (offeredcourseid, coursename) => {
    setOfferedcourseid(offeredcourseid);
    setCoursename(coursename);
    setLgShow(true);
  }
  const reload=()=>window.location.reload();
  const [data, setData] = useState([]); 
  const [enrolledInfo, setEnrolledInfo] = useState([]); 

  useEffect( () => {
    getCourseoffered();
    getStudents();
    // getInfoCourseoffered();
    // getStudents();
    // getEnrolledList(); 
  },[]);

  // melakukan ini adalah dengan menggunakan Promise.all agar permintaan dapat dijalankan secara paralel.

  // const getStudents = async () => {
  //   const students = await EnrollmentService.getStudentToEnroll(offeredcourseid)
  //   setStudents(students.data);
  //   // console.log(students.data)
  // }

  const getStudents = async () => {
    const students = await StudentService.getAll();
    // console.log("students:", students)
    setStudents(students.data.data || []);
  }
  
  const getCourseoffered = async () => {
    const students = await OfferedcourseService.getEnrolledCourse();
    let resp = students.data.data
    // console.log("Original response:", resp);

    // Iterasi setiap course_id dan memanggil getEnrolledCourse untuk masing-masing course
    const courses = await Promise.all(
      resp.map(async (item) => {
        const courseDetails = await OfferedcourseService.getEnrolledCourseInfo(item.course_id);
        // console.log("courseDetails :", courseDetails)
        return {
          ...item,
          num_of_student: courseDetails.data.data.length-1,
          courseDetails: courseDetails.data, // Menyimpan detail course ke dalam objek item
        };
      })
    );
    // console.log("Updated data with course details:", courses);
    // console.log("here :", students)
    setData(courses || []);
    
  }

  // TODO : ngeset map data disini dia
  // const getEnrolledList = async () => {
  //   const offeredCourses = await OfferedcourseService.getActiveOfferedcourse();
  //   // console.log(offeredCourses?.data);
  //   const RowData = offeredCourses?.data.map(async({ id,is_active,learningperiodid,courseid,categoryid }) => {
  //     try {
  //       const [learningperiodData, courseData, categoryData, enrolledstudentData] = await Promise.all([
  //         LearningperiodService.getLearningperiodById(learningperiodid),
  //         CourseService.getCourseById(courseid),
  //         CategoryService.getCategoryById(categoryid),
  //         EnrollmentService.getStudentParticipant(id)
  //       ])

  //       return {
  //         id: id,
  //         // eslint-disable-next-line
  //         is_active: is_active == 1 ? 'Active' : 'Not Active',
  //         category: categoryData.data?.name,
  //         course: courseData?.data?.name_en,
  //         learningperiod: learningperiodData.data?.name,
  //         enrolledstudent: enrolledstudentData.data?.enrolledstudent
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })
  //   return await Promise.all(RowData)
  //     .then((values) => setData(values))
  //     .catch((error) => console.log(error))
  // }

  // console.log(data);

  const navigate = useNavigate();

  const saveOfferedcourse = async (e) => {
      e.preventDefault();
      await OfferedcourseService.addEnrolledCourse({
          course_id: parseInt(coursename, 10),
          user_id: parseInt(studentid, 10),
          role_id : 5
      })
      .then((data) => {
        if (!data.data.error) {
          alert("Offered Course berhasil ditambahkan!")
          setLgShow(false)
      }     
    });
  }
  
  // TODO : last!! panggil endpoint enroll aja ini
  // role_id, user_id, course_id
  const enrolStudent = async () => {
    SyncMoodleService.getMoodleCourse(offeredcourseid)
    .then((data) => {
      console.log('data course: ',data.data);
      const coursedata = data.data;

      const enrollment_data = {
        'offeredcourseid': offeredcourseid,
        'studentid': studentid
      }
      console.log('enrol student data: ',enrollment_data);
      EnrollmentService.addEnrolledstudent(enrollment_data)
      .then ((data) => {
        console.log('Inserted enrolled student: ',data);

        SyncMoodleService.getUserByStudentid(studentid)
        .then((data) => {
          console.log('data student: ',data.data)
          const moodle_user = data.data;
          const mdl_enrol_data = {
            'enrolments[0][courseid]': coursedata.courseid,
            'enrolments[0][userid]': moodle_user.userid
          }
  
          console.log('manual enrol data: ',mdl_enrol_data)
          MoodleService.enrolCourse(
            mdl_enrol_data['enrolments[0][courseid]'],
            mdl_enrol_data['enrolments[0][userid]'],
            5 //student
          )
          .then((data) => {
            console.log('Error enrollment: ',data);

            setLgShow(false)
          })
      })

      })
      
    })

  }
  
  if (data.length === 0) {
    return( 
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Enrolled Students</h1>
            {/* <a href="/offeredcourse/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-book-open"></i> <span>Add New Offered Course</span>
            </a> */}
        </div>
        <div><p className='fs-6 fw-semibold'>The offered course data is empty. Please add some new offered courses !!</p></div>
      </div>
    )
  }

  return (
  <div className='container-fluid'>
    <div className="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 className="h3 mb-0 text-gray-800">Enrolled Students</h1>
      {/* <a href="/offeredcourse/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
        <i className="fas fa-fw fa-book-open"></i> <span>Add New Offered Course</span>
      </a> */}
    </div>
    <table className='table table-sm'>
      <thead>
        <tr>
          <th>Course Name</th>
          <th>Status</th>                       
          <th>Num of Student</th>
          <th>Actions</th>
        </tr>
      </thead>

      <tbody className='table-group-divider'>
      { data.map(( student, index) => (
        <tr key={student.id}>
          <td>{student.course_id}-{student.course_name}</td>
          <td>
            {student.is_active ? (
              <AiIcons.AiOutlineCheck size={20} color="green" />
            ) : (
              <AiIcons.AiOutlineClose size={20} color="red" />
            )}
          </td>
          <td>{student.num_of_student}</td>
          <td>
            {/* <Link title='Detail' to={`/enroll/`+student.id}><BiIcons.BiDetail size={18} /></Link>&nbsp;&nbsp; */}
            <Link title='Add Student Participant' onClick={ () => handleShow(student.id,student.course_id) }><BsIcons.BsFillPlusCircleFill size={18} /></Link>
            {/* <Link title='Delete' onClick={()=>deleteOfferedcourse(offeredcourse.id)}><AiIcons.AiTwotoneDelete size={18} /></Link> */}
          </td>
        </tr>
      ))}
      </tbody>
    </table>

    <Modal
      size="lg"
      show={lgShow}
      onHide={() => setLgShow(false)}
      onExit={reload}
      aria-labelledby="example-modal-sizes-title-lg"
    >        
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Add Student Participant
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={saveOfferedcourse} 
      // onFocus={getStudents(offeredcourseid)}
      >
      <Modal.Body>          
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Offered Course</label>
            <div className='col-sm-5'>{offeredcourseid}</div>          
          </div>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Course Name</label>
            <div className='col-sm-5'>{coursename}</div>          
          </div>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Student to Enroll</label>
            <div className='col-sm-5'>
              <select className='form-select' value={ studentid } onChange={ (e) => setStudentid(e.target.value) }>
                <option>-Select student-</option>
                {students.map((student, index) => {
                return (
                <option key={index} value={student.mdl_id}>
                  {`${student.username} ( ${student.firstname} ${student.middlename} ${student.lastname} )`}
                </option>
                )
              })}
              </select>
            </div>
          </div>          
      </Modal.Body>

      <Modal.Footer>
        <div className="field">
          <Link className='btn btn-outline-danger btn-sm' onClick={handleClose}><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={saveOfferedcourse}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>
      </Modal.Footer>
      </form>
    </Modal>
  </div>
)}


export default EnrollmentList