import HttpCommon from "../../HttpCommon";
import HttpProdi from "../../HttpSmartProdi";


class OfferedcourseService {
    getAll() {
        return HttpProdi.get("/offeredcourse");
    }

    getOfferedcourseById (offeredcourseId) {
        return HttpCommon.get("/offeredcourse/"+offeredcourseId);
    }

    addOfferedcourse(offeredcourse) {
        return HttpCommon.post("/offeredcourse",offeredcourse);
    }

    putOfferedcourse(offeredcourse, offeredcourseId) {
        return HttpCommon.put("/offeredcourse/"+offeredcourseId, offeredcourse);
    }

    deleteOfferedcourse(offeredcourseId) {
        return HttpCommon.delete("/offeredcourse/"+offeredcourseId);
    }

    getActiveOfferedcourse() {
        return HttpCommon.get("/offeredcourse/active/1");
    }

    getEnrolledCourse () {
        try {
            return HttpProdi.get("/enroll");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addEnrolledCourse (offeredcourse) {
        try {
            return HttpProdi.post("/enroll",offeredcourse);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getEnrolledCourseInfo (id) {
        try {
            return HttpProdi.post("/enrolledcourse/" + id);
        } catch (error) {
            console.log(error)
            return error
        }
    }
}

export default new OfferedcourseService();