import axios from "axios";

const baseUrlBE = "https://backend.smartprodi.my.id/v1"
const baseLocal = "http://localhost:8000/v1"

export default axios.create({
  baseURL: baseUrlBE,
  // baseURL: baseLocal,
  headers: {
    "Content-type": "application/json"
  }
});