import HttpCommon from "../../HttpCommon";
import HttpProdi from "../../HttpSmartProdi";

const clientId = 'frontend'; // Replace with your actual client_id
const apiKey = 'a60ab88e2afedd6fdf637cdc4be4c12205eb8fe644726c0b9f121b3f0a1044e9';     // Replace with your actual x-api-key

class CourseService {
    getAll = () =>{
        try {
            return HttpProdi.get("/course");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getCourseById (courseId) {
        try {
            return HttpProdi.get("/course/"+courseId);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addCourse = async(course) => {
        try {
            const courseArray = [course];
            console.log("oayload :", courseArray)
            return await HttpProdi.post("/course", courseArray);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    // TODO:
    putCourse(course, courseId) {
        return HttpCommon.put("/course/"+courseId, course);
    }
    
    deleteCourse = (courseId) => {
        try {
            return HttpProdi.delete("/course/"+courseId, {
                headers: {
                  "X-Api-Key": apiKey, 
                  "X-Client_id": 'frontend', 
                  "Content-type": "application/json"
                }
              }
            );
        } catch (error) {
            console.log(error)
        }
    }
}

export default new CourseService();