import React, {useState, useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import * as AiIcons from 'react-icons/ai';
import CategoryService from '../category/CategoryService';
import StudentService from '../student/StudentService';
import CourseService from './CourseService';
import * as FiIcons from 'react-icons/fi';
import "react-datepicker/dist/react-datepicker.css"; // Import CSS untuk datepicker

const AddCourse = () => {
  const [id, setId] = useState('');
  const [categoryID, setCategoryId] = useState('');

  const [title, setName_id] = useState('');
  const [course_id_number, setCourseidnumber] = useState('');
  const [course_short_name, setName_en] = useState('');
  const [course_summary, setDescription] = useState('');

  const [course_format, setFormat] = useState('topics');
  const [course_credit, setCredit] = useState('');
  // const [course_number_sections, setCourse_id_number] = useState('');
  // const [course_semester, setCourse_id_number] = useState('');
  const [course_start_date, setStartDate] = useState('20-08-2024-08:00');
  const [lgShow, setLgShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [gradeComponents, setGradeComponents] = useState([]);
  const navigate = useNavigate();
  
  const [categorydescription, setCategorydescription] = useState('');
  const [categoryname, setCategoryname] = useState('');
  const [categoryidnumber, setCategoryidnumber] = useState('');
  const [gradeCompID, setGradeCompID] = useState('');

  useEffect( () => {
    getCategories();
    getGradeComponents();  
  },[]);

  const getCategories = async () => {
    let categories = await CategoryService.getAll();
    categories = categories.data.data
 
    setCategories(categories || []);
  }

  const getGradeComponents = async () => {
    let resComp = await StudentService.getGrade();
    resComp = resComp.data.data
    setGradeComponents(resComp );
  }

  const handleClose = () => setLgShow(false);
  const reload=()=>window.location.reload();

  const saveCategory = async (e) => {
    e.preventDefault();
    await CategoryService.addCategory({
      name: categoryname,
      description: categorydescription,
      id_number : categoryidnumber,
    }).then((data) => {
      if (!data.data.error) {
        alert("Category berhasil ditambahkan!")
        setLgShow(false)
      }      
    })    
  }

  const formatDate = (dateString) => {
    // Ambil tanggal dan waktu dari string
    const [datePart, timePart] = dateString.split('T');
    
    // Pecah datePart menjadi komponen-komponennya (YYYY-MM-DD)
    const [year, month, day] = datePart.split('-');
    
    // Susun kembali menjadi format 'DD-MM-YYYY-HH:mm'
    return `${day}-${month}-${year}-${timePart}`;
  }
  
  const saveCourse =  (e) => {
    e.preventDefault();
    const formattedDate = formatDate(course_start_date); 
    CourseService.addCourse({
        category_id: parseInt(categoryID, 10), 
        grade_component_id: parseInt(gradeCompID, 10),
        title: title,
        course_id_number: course_id_number,
        course_format: course_format,
        course_credit : parseInt(course_credit, 10),
        course_start_date : formattedDate,
        course_semester : 1,
        course_short_name : title, //todo:
        course_summary: course_summary,
        course_number_sections : 5
        // curriculum_year: curriculum_year,

    });
    alert("Successfully add data course!")
    navigate("/course");
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/course'>Courses Data</Breadcrumb.Item>
          <Breadcrumb.Item active>Add New Course</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <form onSubmit={ saveCourse }>

      <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Name</label>
          <div className='col-sm-5'><input 
            type="text"
            className="form-control"
            value={ title } 
            onChange={ (e) => setName_id(e.target.value) }
            placeholder="Course Name"
            required
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course ID</label>
          <div className='col-sm-5'><input 
            type="text"
            className="form-control"
            value={ course_id_number } 
            onChange={ (e) => setCourseidnumber(e.target.value) }
            placeholder="Course ID"
            required
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Category</label>
          <div className='col-sm-3'>
            <select className='form-select' value={ categoryID } required onChange={ (e) => setCategoryId(parseInt(e.target.value, 10)) }>
              <option>-Select category-</option>
              {categories?.map((category, index) => {
                return (
                  <option key={index} value={category.mdl_category_id}>
                  {category.name}
                  </option>
                )
              })}              
            </select>
          </div>
          <div className='col-sm-3'>
            <Link title='Save' className='btn btn-outline-success btn-sm' onClick={() => setLgShow(true)}><FiIcons.FiFolderPlus size={18} /> New Category</Link>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              onExit={reload}
              aria-labelledby="example-modal-sizes-title-lg"
            >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add New Category
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={saveCategory}>
        <Modal.Body>          
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Title</label>
              <div className='col-sm-10'><input 
                type="text"
                className="form-control"
                value={ categoryname } 
                onChange={ (e) => setCategoryname(e.target.value) }
                placeholder="Category Title"
                required
              /></div>          
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">ID Number</label>
              <div className='col-sm-10'><textarea
                className="form-control"            
                placeholder="Id Number"
                value={categoryidnumber}
                onInput={ (e) => setCategoryidnumber(e.target.value) }
                required
              >          
              </textarea></div>
            </div>    
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Description</label>
              <div className='col-sm-10'><textarea
                className="form-control"            
                placeholder="Category Description"
                value={categorydescription}
                onInput={ (e) => setCategorydescription(e.target.value) }
                required
              >          
              </textarea></div>
            </div>          
        </Modal.Body>
        <Modal.Footer>
          <div className="field">
            <Link className='btn btn-outline-danger btn-sm' onClick={handleClose}><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
            &nbsp;&nbsp;
            <Link title='Save' className='btn btn-outline-success btn-sm' onClick={saveCategory}><AiIcons.AiFillSave size={18} />Save</Link>
          </div>
        </Modal.Footer>
        </form>
      </Modal>
          </div>
        </div>  

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Grade Component</label>
          <div className='col-sm-5'>
            <select className='form-select' value={ gradeCompID } required onChange={ (e) => setGradeCompID(parseInt(e.target.value, 10)) }>
              <option>-Select grade component-</option>
              {gradeComponents?.map((gc, index) => {
                return (
                  <option key={index} value={gc.id}>
                  {gc.name}
                  </option>
                )
              })}              
            </select>
          </div>
        </div>
        
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Format</label>
          <div className='col-sm-5'>
            <select className='form-select'  value={ course_format } onChange={ (e) => setFormat(e.target.value) }>
            <option>-Select Course Format-</option>
              <option value="topics">Block</option>
              <option value="weeks">Weekly</option>
            </select>
          </div>

        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Credit</label>
          <div className='col-sm-5'>
            {/* <input 
            type="text"
            className="form-control"
            value={ course_credit } 
            onChange={ (e) => setCredit(e.target.value) }
            placeholder="credits"
            required
          /> */}
          <select className='form-select'  value={ course_credit } required onChange={ (e) => setCredit(e.target.value) }>
            <option>-Select Course Credits (SKS)-</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="6">6</option>
              <option value="8">8</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>

        {/* <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course StartDate</label>
          <div className='col-sm-10'><input 
            type="text"
            className="form-control"
            value={ course_start_date } 
            onChange={ (e) => setStartDate(e.target.value) }
            placeholder="20-08-2024-08:00"
            required
          /></div>
        </div> */}

        {/* Native datetime-local input untuk Course Start Date */}
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Start Date</label>
          <div className='col-sm-5'>
            <input 
              type="datetime-local"
              className="form-control"
              value={course_start_date}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </div>
        </div>

        {/*
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Credit</label>
          <div className='col-sm-10'><input 
            type="text"
            className="form-control"
            value={ credit } 
            onChange={ (e) => setCredit(e.target.value) }
            placeholder="Credit"
          /></div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Semester</label>
          <div className='col-sm-10'><input 
            type="text"
            className="form-control"
            value={ semester } 
            onChange={ (e) => setSemester(e.target.value) }
            placeholder="Semester"
          /></div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Curriculum</label>
          <div className='col-sm-10'><input 
            type="text"
            className="form-control"
            value={ curriculum_year } 
            onChange={ (e) => setCurriculum_year(e.target.value) }
            placeholder="Curriculum"
          /></div>
        </div>
        */}
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Description</label>
          <div className='col-sm-5'><textarea
            className="form-control"            
            placeholder="Course Description"
            value={course_summary}
            onInput={ (e) => setDescription(e.target.value) }
            required
          >          
          </textarea></div>
        </div>        
        <div className="field">
          <Link to={`/course`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          {/* <Link title='Save' className='btn btn-outline-success btn-sm' onClick={saveCourse}><AiIcons.AiFillSave size={18} />Save</Link> */}
          <button type="submit" className='btn btn-outline-success btn-sm'>
            <AiIcons.AiFillSave size={18} /> Save
          </button>
        </div>
      </form>
    </div>    
  )
}

export default AddCourse