import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import CourseService from './CourseService';

import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';


const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 10; // Jumlah item per halaman

  useEffect( () => {
    getCourses();
    // category & grade component
  },[]);

  const getCourses = async () => {
    const courses = await CourseService.getAll();
    setCourses(courses.data.data || []);
  }

  const deleteCourse = async (id) => {
    const courses = await CourseService.deleteCourse(id);
    getCourses();

    // Pastikan currentPage valid setelah penghapusan
    const newTotalPages = Math.ceil((courses.length - 1) / itemsPerPage); 
    if (currentPage > newTotalPages) {
      setCurrentPage(newTotalPages);
    }
  }

  const handleDeleteClick = (id) => {
    setSelectedCourseId(id);
    setShowConfirm(true);
  };

  const handleConfirmDelete = () => {
    if (selectedCourseId) {
      deleteCourse(selectedCourseId);
    }
    setShowConfirm(false);
  };

  const handleCancelDelete = () => {
    setShowConfirm(false);
    setSelectedCourseId(null);
  };

   // Filter data siswa berdasarkan pencarian
   const filteredCourse = courses.filter((course) => {
    return (
      // course.idnumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.format.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Hitung total halaman
  const totalPages = Math.ceil(filteredCourse.length / itemsPerPage);

  // Ambil data yang sesuai dengan halaman saat ini
  const currentCourses = filteredCourse.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Fungsi untuk pindah halaman
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // inline style css
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    width: '300px',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };

  if (courses.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Courses Data</h1>
            <a href="/course/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-chalkboard-teacher"></i> <span>Add New Course</span>
            </a>
        </div>
        <div><p className='fs-6 fw-semibold'>The course data is empty. Please add some new courses !!</p></div>
     </div>
    )    
  }

  return (
      <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Courses Data</h1>
          <a href="/course/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
          <i className="fas fa-fw fa-chalkboard-teacher"></i> <span>Add New Course</span>
          </a>
      </div>

      {/* Input Pencarian */}
      <div className='mb-3'>
          <input
            type='text'
            className='form-control'
            placeholder='Search courses...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
      </div>

      <table className='table table-sm'>
        <thead>
          <tr>
          <th>ID Course</th>
          <th>Course Name</th>
          <th>Format Course</th>
          {/*
          <th>Semester</th>
          <th>Curriculum Year</th>
          */}
          <th>Sort Description</th>
          <th>Actions</th>
          </tr>
        </thead>

        <tbody className='table-group-divider'>
          {/* format site dihide */}
          { currentCourses.filter(course => course.format !== 'site').map((course, index) => (
              <tr key={course.idnumber}>
                  <td>{course.course_id_number}</td>
                  <td>{course.name}</td>
                  <td>{course.format}</td>
                  {/*
                  <td>{course.semester}</td>
                  <td>{course.curriculum_year}</td>
                  */}
                  <td>
                    { 
                      (course.summary).length > 30 
                      ? (course.summary).substring(0, 30) + "..." 
                      : course.summary
                    }
                  </td>
                  <td>
                      <Link title='Detail' to={`/course/`+course.mdl_course_id}>
                        <BiIcons.BiDetail size={18} />
                      </Link>&nbsp;&nbsp;&nbsp;
                      {/* <Link title='Edit' to={`/course/edit/`+course.id}><AiIcons.AiFillEdit size={18} /></Link>&nbsp;&nbsp;&nbsp; */}
                      {/* <Link title='Delete' onClick={()=>deleteCourse(course.id)}><AiIcons.AiTwotoneDelete size={18} /></Link> */}

                      <Link title='Delete' className='btn btn-outline-danger btn-sm' onClick={() => handleDeleteClick(course.mdl_course_id)}><AiIcons.AiTwotoneDelete size={18} /></Link>
                  </td>
              </tr>
          )) }
        </tbody>
      </table>

    {/* Pagination Controls */}
    <nav>
      <ul className="pagination">
        {[...Array(totalPages).keys()].map((number) => (
          <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(number + 1)}>
              {number + 1}
            </button>
          </li>
        ))}
      </ul>
    </nav>

    {/* Modal konfirmasi */}
    {showConfirm && (
      <div style={modalStyle}>
        <div style={modalContentStyle}>
          <p>Are you sure you want to delete this course?</p>
          <button className="btn btn-danger" onClick={handleConfirmDelete}>Confirm</button>&nbsp;&nbsp;
          <button className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
        </div>
      </div>
    )}
    </div>
  )
}

export default CourseList